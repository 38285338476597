import React from "react";
import Page from "./Page";
import Link from "../Link";
import Paragraph from "../Paragraph";

function listInEnglish(strings) {
  if (strings.length === 0) return "";
  if (strings.length === 1) return strings[0];
  return `${strings.slice(0, strings.length - 1).join(", ")} and ${
    strings[strings.length - 1]
  }`;
}

function Info({
  imageSrc,
  href,
  links,
  label,
  description,
  principalInvestigators,
  analysts,
}) {
  return (
    <div className="flex flex-col md:flex-row py-2">
      {imageSrc && (
        <img src={imageSrc} alt={label} className="md:w-1/4 lg:w-1/5 m-auto" />
      )}
      <div className="flex flex-col flex-1 p-4 pt-0">
        <div className="text-xl font-bold pb-2">
          {href && <Link href={href}>{label}</Link>}
          {!href && label}
          <div>
            {links &&
              links.map(({ text, href }) => (
                <Link key={href} href={href} className="mr-5">
                  {text}
                </Link>
              ))}
          </div>
        </div>
        <Paragraph>{description}</Paragraph>
        <Paragraph>
          <span className="font-orstedSansBold">
            Principal Investigator{principalInvestigators.length > 1 ? "s" : ""}
            :
          </span>{" "}
          {listInEnglish(principalInvestigators)}
        </Paragraph>
        <Paragraph>
          <span className="font-orstedSansBold">
            Analyst{analysts.length > 1 ? "s" : ""}:
          </span>{" "}
          {listInEnglish(analysts)}
        </Paragraph>
      </div>
    </div>
  );
}

export default function AccessDataPage({ dataObjects = [] }) {
  return (
    <Page>
      {dataObjects.map((info) => (
        <Info key={info.label} {...info} />
      ))}
    </Page>
  );
}
