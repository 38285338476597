import React from "react";

export default function ({ className, href, children, noColor }) {
  return (
    <a
      className={`${noColor ? "" : "text-orsted"} ${className}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
