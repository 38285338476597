import React from "react";
import Page from "./Page";
import Link from "../Link";
import Paragraph from "../Paragraph";

function Info({ name, email, description, imageSrc }) {
  return (
    <div className="flex flex-col md:flex-row py-2">
      {imageSrc && (
        <img
          src={imageSrc}
          alt={name}
          className="md:w-1/5 lg:w-1/6 m-auto rounded"
        />
      )}
      <div className="flex flex-col flex-1 p-4 pt-0">
        <div className="text-lg">{name}</div>
        <Paragraph>
          <Link href={`mailto: ${email}`}>{email}</Link>
        </Paragraph>
        {description.split("\n").map((description, i) => (
          <Paragraph key={i}>{description}</Paragraph>
        ))}
      </div>
    </div>
  );
}

export default function TeamPage({ dataObjects = [] }) {
  return (
    <Page>
      {dataObjects.map((info) => (
        <Info key={info.email} {...info} />
      ))}
    </Page>
  );
}
