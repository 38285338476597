// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/OrstedSans-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/OrstedSans-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/OrstedSans-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/OrstedSans-Black.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/OrstedSans-Italic.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"OrstedSansRegular\";\n  font-display: block;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"OrstedSansLight\";\n  font-style: normal;\n  font-weight: 200;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"OrstedSansBold\";\n  font-style: normal;\n  font-weight: 800;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"OrstedSansBlack\";\n  font-style: normal;\n  font-weight: 900;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"OrstedSansItalic\";\n  font-style: italic;\n  font-weight: 400;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\");\n}\n", ""]);
// Exports
module.exports = exports;
