import React from "react";
import Page from "./Page";
import Paragraph from "../Paragraph";
import SectionTitle from "../SectionTitle";
import Link from "../Link";

import glider_in_water from "../../images/home/glider_in_water.png";
import GliderLocationMap from "../GliderLocationMap";

function Info({
  className,
  title,
  href,
  imageAlt,
  description,
  imageSrc,
  imageLink,
  main,
  children,
}) {
  return (
    <div className={className || "flex flex-col md:flex-row py-2"}>
      {imageSrc && imageLink && (
        <Link href={imageLink} className="p-2 w-40 m-auto">
          <img src={imageSrc} alt={imageAlt} className="m-auto" />
        </Link>
      )}
      <div
        className={`flex flex-col flex-1 p-2 m-2 rounded ${
          main ? "" : "bg-orstedBgLight"
        }`}
      >
        <SectionTitle className={`font-bold ${main ? " text-2xl" : ""}`} first>
          {href && <Link href={href}>{title}</Link>}
          {!href && title}
        </SectionTitle>
        {description.split("\n\n").map((description, i) => (
          <Paragraph key={i} className={main ? "text-lg" : ""}>
            {description}
          </Paragraph>
        ))}
      </div>
      {children}
    </div>
  );
}

export default function HomePage({ dataObjects }) {
  const firstDataObject = dataObjects[0];
  const otherDataObjects = dataObjects.slice(1, dataObjects.length);
  return (
    <Page>
      <div
        className="w-full bg-cover bg-center"
        style={{ backgroundImage: `url(${glider_in_water})` }}
      >
        <div className="h-24 sm:h-32 md:h-40 lg:h-56 xl:h-64" />
      </div>
      <Info
        className="flex flex-col items-center lg:flex-row py-2"
        key={firstDataObject.title}
        {...firstDataObject}
      >
        <div className="w-full md:w-1/2 xl:w-1/3">
          <div
            className="relative w-full h-0"
            style={{ paddingBottom: "56.25%" }}
          >
            <iframe
              title="ECO-PAM: Listening for Whales in a Changing Climate"
              className="absolute w-full h-full left-0 top-0"
              src="https://www.youtube.com/embed/m5a0I03OAok"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </Info>
      <div style={{ height: "34em" }}>
        <GliderLocationMap />
      </div>
      {otherDataObjects.map((dataObject) => (
        <Info key={dataObject.title} {...dataObject} />
      ))}
    </Page>
  );
}
