import React from "react";
import Page from "./Page";
import Paragraph from "../Paragraph";
import SectionTitle from "../SectionTitle";
import Link from "../Link";

function Info({
  title,
  href,
  imageAlt,
  description,
  imageSrc,
  imageLink,
  main,
}) {
  return (
    <div className="flex flex-col md:flex-row py-2">
      {imageSrc && imageLink && (
        <Link href={imageLink} className="p-2 w-40 m-auto">
          <img src={imageSrc} alt={imageAlt} className="m-auto" />
        </Link>
      )}
      <div
        className={`flex flex-col flex-1 p-2 m-2 rounded ${
          main ? "" : "bg-orstedBgLight"
        }`}
      >
        <SectionTitle className={`font-bold ${main ? " text-2xl" : ""}`} first>
          {href && <Link href={href}>{title}</Link>}
          {!href && title}
        </SectionTitle>
        {description.split("\n\n").map((description, i) => (
          <Paragraph key={i} className={main ? "text-lg" : ""}>
            {description}
          </Paragraph>
        ))}
      </div>
    </div>
  );
}

export default function PartnerInstitutionsPage({ dataObjects }) {
  return (
    <Page>
      {dataObjects.map((dataObject) => (
        <Info key={dataObject.title} {...dataObject} />
      ))}
    </Page>
  );
}
