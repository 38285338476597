import React from "react";
import Page from "./Page";
import Paragraph from "../Paragraph";

import EarlyFallImage from "../../images/cold_pool/early_fall.png";
import LateFallImage from "../../images/cold_pool/late_fall.png";
import WinterImage from "../../images/cold_pool/winter.png";
import MapImage from "../../images/cold_pool/cold_pool_map.png";

const earlyFallColor = "#367DB7";
const lateFallColor = "#00B050";
const winterColor = "#984CA3";

export default function TheColdPoolPage() {
  return (
    <Page>
      <div className="flex flex-col md:flex-row">
        <div className="">
          <Paragraph className="text-lg">
            Since 2020, ocean temperature data collected by the ECO-PAM glider
            provides high-resolution mapping of the cold pool, a seasonal ocean
            feature that supports regional ecosystems, and associated
            stratification across the continental shelf.
          </Paragraph>
          <Paragraph>
            The deployments in the early fall, late fall, and winter provide
            seasonally dependent cold pool data that capture the cross shelf
            stratification in the{" "}
            <span
              className="font-orstedSansBold"
              style={{ color: earlyFallColor }}
            >
              early fall
            </span>
            , the break down in the{" "}
            <span
              className="font-orstedSansBold"
              style={{ color: lateFallColor }}
            >
              late fall
            </span>{" "}
            and the{" "}
            <span
              className="font-orstedSansBold"
              style={{ color: winterColor }}
            >
              winter
            </span>{" "}
            pre-conditioning for the next seasonal cold pool initiating in the
            spring. These seasonally distributed data contribute to cold pool
            research critical to the assessment of possible offshore wind
            impact.
          </Paragraph>
          <Paragraph>
            The cold pool mapping data provided by the ECO-PAM project is sent
            in realtime to the National Oceanic and Atmospheric Administration
            (NOAA). These data are combined in a collaborative effort with
            academic institutions, the Navy, and other NOAA project partners and
            integrated into operational ocean and atmospheric hurricane forecast
            models used by decision makers to warn and protect coastal
            communities.
          </Paragraph>
          <Paragraph>
            The publicly available ECO-PAM data will also be used by researchers
            to better understand ocean mixing processes during both powerful
            hurricanes and Nor’easters. This includes how storms impact and
            interact with the Mid Atlantic Cold Pool.
          </Paragraph>
        </div>
        <div className="flex-grow flex flex-col xl:flex-row">
          <div style={{ minWidth: "30vw" }}>
            <img src={MapImage} alt="Map of ECO-PAM gliders" />
          </div>
          <div className="flex-grow flex flex-col" style={{ minWidth: "25vw" }}>
            <div>
              <img src={EarlyFallImage} alt="Chart for early fall" />
            </div>
            <div>
              <img src={LateFallImage} alt="Chart for late fall" />
            </div>
            <div>
              <img src={WinterImage} alt="Chart for winter" />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
