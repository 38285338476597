import React from "react";
import Page from "./Page";

import WhoiBuoy from "../../images/technology/whoi_bouy.png";
import SlocumGlider from "../../images/technology/slocum_glider.png";
import Link from "../Link";

export default function TechnologyUsedPage() {
  return (
    <Page>
      <div className="flex flex-col xl:flex-row items-center xl:items-start">
        <div className="xl:h-full xl:w-2/3 p-3">
          <Link
            href="http://www.teledynemarine.com/slocum-glider"
            className="text-xl font-bold"
          >
            Slocum Glider
          </Link>
          <img
            src={SlocumGlider}
            alt="Slocum glider infographic"
            className="w-full m-auto"
          />
        </div>
        <div className="w-3/4 xl:h-full xl:w-1/3 p-3">
          <p className="text-xl font-bold">W.H.O.I Buoy</p>
          <img
            src={WhoiBuoy}
            alt="WHOI buoy infographic"
            className="w-full m-auto"
          />
        </div>
      </div>
    </Page>
  );
}
