import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  NavLink,
  Redirect,
  useLocation,
} from "react-router-dom";

import HomePage from "./components/pages/HomePage";
// import EcoPamProjectOverviewPage from "./components/pages/EcoPamProjectOverviewPage";
import AccessDataPage from "./components/pages/AccessDataPage";
import TechnologyUsedPage from "./components/pages/TechnologyUsedPage";
import TeamPage from "./components/pages/TeamPage";
import PartnerInstitutionsPage from "./components/pages/PartnerInstitutionsPage";

import Link from "./components/Link";

import OrstedLogo from "./images/logo/orsted_blue.png";
import RutgersLogo from "./images/logo/rutgers.png";
import WhoiLogo from "./images/logo/whoi.png";
import UriLogo from "./images/logo/uri.png";
import AxiomLogo from "./images/logo/axiom.svg";

import MamvImage from "./images/access_data/mamv0720_mamv_summary_map.png";
import NjatlImage from "./images/access_data/njatl0720_njatl_summary_map.png";
import RutgersImage from "./images/access_data/rutgers0720_ru34_summary_map.png";
import JamesMillerImage from "./images/team/james_miller.png";
import JosephBrodieImage from "./images/team/joseph_broadie.png";
import JoshKohutImage from "./images/team/josh_kohut.png";
import MarkBaumgartnerImage from "./images/team/mark_baumgartner.png";
import YingTsongLinImage from "./images/team/ying_tsong_lin.png";
import TheColdPoolPage from "./components/pages/TheColdPoolPage";

const rutgersLink = "https://www.rutgers.edu/";
const whoiLink = "https://www.whoi.edu/";
const uriLink = "https://www.uri.edu/";
const axiomLink = "https://www.axiomdatascience.com/";

function DropdownItem({ to, children }) {
  const className =
    "text-sm p-2 block bg-orsted hover:bg-orstedLight text-orstedTextLight font-orstedSansLight";
  if (to.startsWith("/")) {
    // Internal link
    return (
      <NavLink
        to={to}
        className={className}
        activeClassName="font-bold bg-orstedLight"
      >
        {children}
      </NavLink>
    );
  }
  // External link
  return (
    <Link className={className} href={to} noColor>
      {children}
    </Link>
  );
}

function NavItem({ to, exact, dropdownItems, children }) {
  const location = useLocation();
  const isActiveTab =
    location.pathname === to ||
    (dropdownItems &&
      dropdownItems.find(
        (dropdownItem) => dropdownItem.href === location.pathname
      ));
  return (
    <span
      className={`group block sm:inline-block mt-4 sm:mt-0 px-1 text-md text-white ${
        isActiveTab ? "bg-orstedLight" : ""
      } hover:bg-orstedLight`}
    >
      {to && (
        <NavLink to={to} exact={exact} activeClassName="font-bold">
          <div className="inline-flex p-2">{children}</div>
        </NavLink>
      )}
      {!to && (
        <div className="inline-flex p-2 cursor-default">
          {children}
          {dropdownItems && (
            <span className="ml-1 mt-px h-5 w-5">
              <svg viewBox="0 0 20 20" fill="currentColor">
                <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </span>
          )}
        </div>
      )}
      {dropdownItems && (
        <div className="invisible group-hover:visible absolute">
          {dropdownItems.map(({ label, href }) => (
            <DropdownItem key={label} to={href}>
              {label}
            </DropdownItem>
          ))}
        </div>
      )}
    </span>
  );
}

const ecoPamProjectOverviewPageObjects = [
  {
    title: "Project Overview",
    description: `The Ørsted Ecosystem and Passive Acoustic Monitoring (ECO-PAM) project is a partnership between Ørsted North America, Rutgers University, Woods Hole Oceanographic Institution, and the University of Rhode Island.\n\nThe purpose of ECO-PAM is to advance research for detection of North Atlantic right whale, to better understand their presence, distribution and seasonality and to contribute to characterization of their habitat in offshore wind lease areas. Specifically, ECO-PAM will be intensely focused on the Ørsted Ocean Wind lease area off of New Jersey, but will also include sampling in Ørsted lease areas around Massachusetts. The project will include two acoustic monitoring buoys deployed by WHOI, an acoustic vector sensor buoy developed by URI, and an autonomous underwater glider deployed by Rutgers. Additional information on these components are available in their respective sections.`,
    main: true,
  },
];

const partnerInstitutionPageObjects = [
  {
    title: "Rutgers Overview",
    description: `The Rutgers University Center for Ocean Observing Leadership (RUCOOL) is deploying a Teledyne Webb Slocum autonomous underwater glider repeatedly for the duration of the project. Additional benefits of the glider missions include collection of tagged fish data disseminated thru the Animal Telemetry Network as well as contribution of data gathered to the NOAA IOOS Glider DAC that will help improve accuracy of weather forecasting and predicting severe storms. Additionally, RUCOOL will be conducting analysis of whale habitat preferences, building up to the development of a predictive habitat model for the North Atlantic right whale.`,
    imageSrc: RutgersLogo,
    imageLink: rutgersLink,
  },
  {
    title: "WHOI Overview",
    description: `Woods Hole Oceanographic Institution is deploying two oceanographic buoys which house a Digital Acoustic Monitoring Instrument, or DMON. The DMON automatically detects the sounds of vocalizing marine mammals, identifies the species based on characteristics of the sounds, and reports which species have been heard to scientists on shore via satellite in near real time. These two buoys deployed for two years as part of ECO-PAM (one off of Atlantic City, NJ, and the other off Martha’s Vineyard, MA) will help to improve monitoring and conservation efforts for whales by providing industry, scientists, managers, and the public with near real-time information on whale presence.`,
    imageSrc: WhoiLogo,
    imageLink: whoiLink,
  },
  {
    title: "URI Overview",
    description: `The University of Rhode Island is leading a team to build and deploy a Vector Sensor Acoustic Listening Real Time Buoy (V-SALSA) for real time monitoring of wind farm construction and operational areas. This buoy consists of four hydrophones arranged as a tetrahedral array with a vector sensor in the middle. This array configuration and the use of a vector sensor have the potential to facilitate direction of arrival estimates and localization of sound sources. The V-SALSA buoy will be deployed in New England waters during 2021.`,
    imageSrc: UriLogo,
    imageLink: uriLink,
  },
];

const accessDataPageObjects = [
  {
    imageSrc: RutgersImage,
    links: [
      {
        text: "Physical Data (Rutgers)",
        href: "https://go.rutgers.edu/ECO-PAM-Glider1",
      },
      {
        text: "Acoustic Data (WHOI)",
        href: "http://dcs.whoi.edu/rutgers0720/rutgers0720_ru34.shtml",
      },
    ],
    label: "Glider Deployment 1",
    description:
      "A Slocum G3 glider was deployed off the coast of Atlantic City, New Jersey, equipped with a sensor to measure water temperature, pressure, and conductivity (salinity), a DMON acoustic sensor to listen for marine mammals, and a VEMCO receiver to detect tagged fish.",
    principalInvestigators: [
      "Josh Kohut (Rutgers University)",
      "Joseph Brodie (Rutgers University)",
      "Mark Baumgartner (Woods Hole Oceanographic Institution)",
    ],
    analysts: ["Julianne Bonnell"],
  },
  {
    imageSrc: NjatlImage,
    href: "http://dcs.whoi.edu/njatl0720/njatl0720_njatl.shtml",
    label: "Atlantic City Buoy",
    description:
      "A DMON buoy was deployed 20 miles southeast of Alantic City, New Jersey on July 30, 2020 to monitor the presence of baleen whales in near real time by automatically detecting and identifying their calls. The buoy will help to improve monitoring and conservation efforts for whales by providing industry, scientists, managers, and the public with near real-time information on whale presence.",
    principalInvestigators: [
      "Mark Baumgartner (Woods Hole Oceanographic Institution)",
    ],
    analysts: ["Julianne Bonnell"],
  },
  {
    imageSrc: MamvImage,
    href: "http://dcs.whoi.edu/mamv0720/mamv0720_mamv.shtml",
    label: "Martha's Vineyard Buoy",
    description:
      "A DMON buoy was deployed 20 miles southwest of Martha's Vineyard, Massachusetts on July 31, 2020 to monitor the presence of baleen whales in near real time by automatically detecting and identifying their calls. The buoy will help to improve monitoring and conservation efforts for whales by providing industry, scientists, managers, and the public with near real-time information on whale presence.",
    principalInvestigators: [
      "Mark Baumgartner (Woods Hole Oceanographic Institution)",
      "Joseph Brodie (Rutgers University)",
      "Mark Baumgartner (Woods Hole Oceanographic Institution)",
    ],
    analysts: ["Julianne Bonnell"],
  },
];

const teamPageObjects = [
  {
    name: "Mark Baumgartner",
    email: "mbaumgartner@whoi.edu",
    description:
      "Dr. Baumgartner is a Senior Scientist in the biology department at the Woods Hole Oceanographic Institute. His research interests focus primarily on top predator ecology and the physical and biological oceanographic processes that allow those predators to survive in the ocean.  He is particularly interested in using baleen whales and their zooplankton prey as a tractable system in which questions can be addressed about how prey behavior, life history, and aggregation mechanisms can influence top predator distribution and behavior.  The Baumgartner lab strives to utilize new technology, novel approaches, and multidisciplinary collaborations to study aspects of both whale and zooplankton ecology in this system, including autonomous underwater vehicles, passive acoustics, archival tags, zooplankton instrumentation, and molecular techniques.",
    imageSrc: MarkBaumgartnerImage,
  },
  {
    name: "James Miller",
    email: "miller@uri.edu",
    description:
      "Dr. Miller is a Professor of Ocean Engineering and Oceanography at the University of Rhode Island. He conducts research and teaches courses in acoustical oceanography, sonar systems engineering, acoustic signal processing, underwater acoustics, marine bio-acoustics,  ocean data analysis, modeling, and underwater vehicle design.  His interests include the acoustics of ocean sediments,  3D acoustic propagation in shallow water and the effects of sea surface waves on acoustic normal modes. He has done work on the effects of ocean noise on marine animals.",
    imageSrc: JamesMillerImage,
  },
  {
    name: "Joseph Brodie",
    email: "jbrodie@marine.rutgers.edu",
    description:
      "Dr. Brodie is the Director of Atmospheric Research at the Rutgers University Center for Ocean Observing Leadership. He is a meteorologist and physical oceanographer with a primary interest in the modeling of the complex coastal environment. His research focuses on using modeling tools to study climate change and working with these tools to further society’s use of renewable energy technologies to reduce its climatic influence. He uses a variety of observations (i.e. lidar, sodar, in situ meteorological buoys and masts) to study our dynamic coastal environment, with the goal of using these observations to improve the modeling of our region for advancing offshore wind energy, along with other coastal stakeholders. These improved models can also be used to study and improve the prediction of other coastal and atmosphere/ocean processes, such as sea breezes and tropical storms.\n",
    imageSrc: JosephBrodieImage,
  },
  {
    name: "Ying-Tsong Lin",
    email: "ytlin@whoi.edu",
    description:
      "Dr. Lin is an Associate Scientist at the Woods Hole Oceanographic Institute. His research focuses on Understanding the ocean, one of the last unexplored frontiers on Earth, advancement of knowledge through interdisciplinary study using acoustics, enabling science through innovative technology, and environmental stewardship and ocean awareness. His research interests include sound propagation physics, computational acoustics, acoustical oceanography/inverse problem, marine mammal acoustics, signal processing; Internal waves.\n",
    imageSrc: YingTsongLinImage,
  },
  {
    name: "Josh Kohut",
    email: "kohut@marine.rutgers.edu",
    description:
      "Dr. Kohut is an Associate Professor at the Rutgers Center of Ocean Observing Leadership. His research interests are focused around the fact that physical processes in the coastal ocean are highly variable in space and time and play a critical role in coupled biological and chemical processes. From events lasting several hours to days on through inter-annual and decadal scales, the variability in the fluid itself structures marine ecological systems. His approach is to apply ocean observing technologies that now sample across these important time and space scales to better understand the physical ocean that structures marine ecosystems. Consequently, this new knowledge has relevance to broader stakeholder communities with interests in the coastal ocean. Working through partnerships across these stakeholder groups, his research is collaborative and supports both science and application. Through these partnerships he is able to frame relevant scientific hypotheses and efficiently translate the output to better management and monitoring.",
    imageSrc: JoshKohutImage,
  },
];

const aboutDropdownItems = [
  {
    href: "/team",
    label: "Team",
  },
  {
    href: "/partner-institutions",
    label: "Partner Institutions",
  },
  {
    href: "/technology-used",
    label: "Technology",
  },
  {
    href: "cold-pool",
    label: "The Cold Pool",
  },
];

const HomePageHydrated = () => (
  <HomePage dataObjects={ecoPamProjectOverviewPageObjects} />
);
// const EcoPamProjectOverviewPageHydrated = () => <EcoPamProjectOverviewPage dataObjects={ecoPamProjectOverviewPageObjects}/>;
const AccessDataPageHydrated = () => (
  <AccessDataPage dataObjects={accessDataPageObjects} />
);
const TeamPageHydrated = () => <TeamPage dataObjects={teamPageObjects} />;
const PartnerInstitutionsPageHydrated = () => (
  <PartnerInstitutionsPage dataObjects={partnerInstitutionPageObjects} />
);

export default function App() {
  return (
    <div className="text-orstedTextDark font-orstedSansRegular">
      <BrowserRouter>
        <header className="w-full">
          <div className="flex flex-col sm:flex-row items-center justify-between bg-white font-orstedSansRegular text-orsted text-3xl">
            <div className="h-8 md:h-10 lg:h-12 flex flex-row flex-shrink-0 items-center divide-x-2 divide-orsted mr-8 leading-none">
              <div className="flex items-center h-full ml-4 pr-5">
                <Link className="h-2/3" href="https://us.orsted.com/">
                  <img
                    src={OrstedLogo}
                    className="orsted-logo h-full object-contain"
                    style={{ marginTop: -2 }}
                    alt="Ørsted logo"
                  />
                </Link>
              </div>
              <Link
                href="https://oceanwind.com/"
                className="font-bold pl-5 text-orsted"
              >
                Ocean Wind
              </Link>
            </div>
            <div className="h-8 md:h-10 lg:h-12 flex flex-row flex-shrink-0 items-center space-x-5 mr-4">
              <Link href={rutgersLink} className="h-4/5">
                <img
                  src={RutgersLogo}
                  alt="Rutgers logo"
                  className="h-full object-contain"
                />
              </Link>
              <Link href={whoiLink} className="h-4/5">
                <img
                  src={WhoiLogo}
                  alt="Woods Hole Oceanographic Institution logo"
                  className="h-full object-contain"
                />
              </Link>
              <Link href={uriLink} className="h-4/5">
                <img
                  src={UriLogo}
                  alt="University of Rhode Island logo"
                  className="h-full object-contain"
                />
              </Link>
              <Link href={axiomLink} className="h-4/5">
                <img
                  src={AxiomLogo}
                  alt="Axiom Data Science logo"
                  className="h-full object-contain"
                />
              </Link>
            </div>
          </div>
          <nav
            className="flex items-center justify-between flex-wrap bg-orsted text-gray-800"
            style={{ lineHeight: 1.3 }}
          >
            <div className="px-5 text-white text-2xl font-bold">
              ECO-PAM Project
            </div>
            <div className="w-full block flex-grow sm:flex sm:items-center sm:w-auto">
              <div className="text-lg sm:flex-grow">
                <NavItem exact to="/">
                  Home
                </NavItem>
                <NavItem to="/access-data">Access Data</NavItem>
                <NavItem dropdownItems={aboutDropdownItems}>About</NavItem>
              </div>
            </div>
          </nav>
        </header>
        <Switch>
          <Route path="/default" render={() => <Redirect to="/" />} />
          <Route path="/" exact component={HomePageHydrated} />
          <Route path="/access-data" exact component={AccessDataPageHydrated} />
          {/*<Route path="/eco-pam-project-overview" exact component={EcoPamProjectOverviewPageHydrated} />*/}
          <Route path="/team" exact component={TeamPageHydrated} />
          <Route
            path="/partner-institutions"
            exact
            component={PartnerInstitutionsPageHydrated}
          />
          <Route path="/technology-used" exact component={TechnologyUsedPage} />
          <Route path="/cold-pool" exact component={TheColdPoolPage} />
          <Route component={HomePage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
