import React, { useEffect, useRef, useState } from "react";

import {
  createContainer,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
} from "victory";
import "mapbox-gl/dist/mapbox-gl.css";
import MapGL, { Layer, Marker, Source } from "react-map-gl";
import bbox from "@turf/bbox";
import WebMercatorViewport from "viewport-mercator-project";
import GliderDeployment1GeoJson from "../data/glider_deployment_1_geojson.json";
import GliderDeployment2GeoJson from "../data/glider_deployment_2_geojson.json";
import GliderDeployment3GeoJson from "../data/glider_deployment_3_geojson.json";
import GliderDeployment4GeoJson from "../data/glider_deployment_4_geojson.json";

import { ReactComponent as PinIcon } from "../images/icons/pin.svg";
import GliderImage from "../images/technology/slocum_glider_no_labels.png";
import Mission1SummaryPdf from "../images/Mission1Summary.pdf";
import Mission2SummaryPdf from "../images/Mission2Summary.pdf";
import Mission3SummaryPdf from "../images/Mission3Summary.pdf";
import Mission4SummaryPdf from "../images/Mission4Summary.pdf";

import Link from "./Link";
import AxiomUIFormatters from "@axds/axiom-ui-formatters";

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

const decimalFormatter = (n) => AxiomUIFormatters.number.format_max_fixed(n, 3);
// XXX date formatter fns shouldn't need to be wrapped (this is not defined if we don't)
const longDateFormatter = (d) => AxiomUIFormatters.date.dateTime(d);

function Pin({ feature, color = "black" }) {
  const sizePixels = 20;
  const { coordinates } = feature.geometry;
  return (
    <Marker
      longitude={coordinates[0]}
      latitude={coordinates[1]}
      offsetLeft={-sizePixels / 2}
      offsetTop={-sizePixels - 3}
    >
      <PinIcon fill={color} width={sizePixels} height={sizePixels} />
      {/*<img src={pin} style={{width: sizePixels, height: sizePixels, color: 'red'}}/>*/}
    </Marker>
  );
}

function FeatureInfoProperty({ label, value, units, valueFormatter }) {
  return (
    <span>
      <span className="font-orstedSansBold">{label}: </span>
      <span>{valueFormatter ? valueFormatter(value) : value}</span>
      {units && <span>{units}</span>}
    </span>
  );
}

function FeatureInfo({ feature }) {
  if (!feature) return null;

  const { time, depth, temperature } = feature.properties;
  const { coordinates } = feature.geometry;
  // TODO pass in units
  return (
    <div>
      <FeatureInfoProperty
        label="Time"
        value={time}
        valueFormatter={longDateFormatter}
      />
      ,{" "}
      <FeatureInfoProperty
        label="Depth"
        value={depth}
        units={" meters"}
        valueFormatter={decimalFormatter}
      />
      ,{" "}
      <FeatureInfoProperty
        label="Temperature"
        value={temperature}
        units={" ºC"}
        valueFormatter={decimalFormatter}
      />
      ,{" "}
      <FeatureInfoProperty
        label="Longitude"
        value={coordinates[0]}
        units={"°"}
        valueFormatter={decimalFormatter}
      />
      ,{" "}
      <FeatureInfoProperty
        label="Latitude"
        value={coordinates[1]}
        units={"°"}
        valueFormatter={decimalFormatter}
      />
    </div>
  );
}

function GliderDetails({ className, hoverFeature }) {
  return (
    <div className={className}>
      {!hoverFeature && (
        <div className="font-orstedSansItalic">
          Hover over a point for details.
        </div>
      )}
      {hoverFeature && <FeatureInfo feature={hoverFeature} />}
    </div>
  );
}

const DepthTimeseriesChart = React.memo(
  ({ data, yDomain, zoomDomain, setZoomDomain, hoverFeature, onHover }) => {
    return (
      <VictoryChart
        scale={{ x: "time" }}
        domain={{ y: yDomain }}
        height={200}
        padding={{ left: 50, right: 20, top: 30, bottom: 30 }}
        containerComponent={
          <VictoryZoomVoronoiContainer
            onActivated={(points) =>
              points && points.length && onHover(points[0])
            }
            zoomDimension="x"
            zoomDomain={zoomDomain}
            onZoomDomainChange={setZoomDomain}
          />
        }
      >
        <VictoryLabel
          text="Glider Depth Over Time"
          x={225}
          y={10}
          textAnchor="middle"
          style={{
            fontSize: 18,
          }}
        />
        <VictoryAxis
          dependentAxis
          label="Depth (m)"
          style={{
            tickLabels: { padding: 4 },
          }}
        />
        <VictoryAxis />
        <VictoryLine
          style={{
            data: { stroke: "#4099da" },
          }}
          x="time"
          y="depth"
          data={data}
        />
        {hoverFeature && (
          <VictoryScatter
            style={{
              data: { fill: "#4099da", stroke: "white", strokeWidth: 4 },
            }}
            size={9}
            data={[
              {
                x: new Date(hoverFeature.properties.time),
                y: hoverFeature.properties.depth,
              },
            ]}
          />
        )}
      </VictoryChart>
    );
  },
  (
    { data: pData, hoverFeature: pHoverFeature, zoomDomain: pZoomDomain },
    { data: nData, hoverFeature: nHoverFeature, zoomDomain: nZoomDomain }
  ) => {
    return (
      !!pData &&
      !!nData &&
      pData.length === nData.length &&
      pHoverFeature === nHoverFeature &&
      rangesEqual(pZoomDomain, nZoomDomain)
    );
  }
);

function rangesEqual(a, b) {
  return a && b && a.x[0] === b.x[0] && a.x[1] === b.x[1];
}

const DepthZoomContextChart = React.memo(
  ({ data, yDomain, zoomDomain, setZoomDomain }) => {
    return (
      <VictoryChart
        scale={{ x: "time" }}
        domain={{ y: yDomain }}
        height={120}
        padding={{ top: 20, bottom: 30, left: 50, right: 20 }}
        containerComponent={
          <VictoryBrushContainer
            brushDimension="x"
            brushDomain={zoomDomain}
            onBrushDomainChange={(domain) =>
              setZoomDomain({ x: domain.x, y: yDomain })
            }
          />
        }
      >
        <VictoryLine
          style={{
            data: { stroke: "#4099da" },
          }}
          data={data}
          x="time"
          y="depth"
        />
        <VictoryAxis />
      </VictoryChart>
    );
  },
  (prev, next) => {
    const { zoomDomain: pZoomDomain } = prev;
    const { zoomDomain: nZoomDomain } = next;
    return rangesEqual(pZoomDomain, nZoomDomain);
  }
);

const DepthChart = React.memo(
  ({
    className,
    data,
    onHover,
    zoomDomain,
    yDomain,
    setZoomDomain,
    hoverFeature,
  }) => {
    return (
      <div className={className}>
        <DepthTimeseriesChart
          data={data}
          yDomain={yDomain}
          zoomDomain={zoomDomain}
          setZoomDomain={setZoomDomain}
          hoverFeature={hoverFeature}
          onHover={onHover}
        />
        <DepthZoomContextChart
          data={data}
          yDomain={yDomain}
          zoomDomain={zoomDomain}
          setZoomDomain={setZoomDomain}
        />
      </div>
    );
  },
  (
    {
      data: prevData,
      hoverFeature: prevHoverFeature,
      zoomDomain: prevZoomDomain,
    },
    {
      data: nextData,
      hoverFeature: nextHoverFeature,
      zoomDomain: nextZoomDomain,
    }
  ) =>
    !!prevData &&
    !!nextData &&
    prevData.length === nextData.length &&
    prevHoverFeature === nextHoverFeature &&
    rangesEqual(prevZoomDomain, nextZoomDomain)
);

const gliderDeployments = [
  {
    label: "Glider Deployment 4",
    href: Mission4SummaryPdf,
    geoJson: GliderDeployment4GeoJson,
  },
  {
    label: "Glider Deployment 3",
    href: Mission3SummaryPdf,
    geoJson: GliderDeployment3GeoJson,
  },
  {
    label: "Glider Deployment 2",
    href: Mission2SummaryPdf,
    geoJson: GliderDeployment2GeoJson,
  },
  {
    label: "Glider Deployment 1",
    href: Mission1SummaryPdf,
    geoJson: GliderDeployment1GeoJson,
  },
];

export default function GliderLocationMap() {
  const mapRef = useRef(null);

  const [dataViewPort, setDataViewPort] = useState(null);
  const [viewPort, setViewPort] = useState({
    latitude: 39.126712304405736,
    longitude: -74.25812072490054,
    zoom: 7.107880217736418,
    bearing: 0,
    pitch: 0,
    width: 100, // nonzero width/height to avoid bbox projection errors on first load :shrug:
    height: 100,
  });
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [hoverFeature, setHoverFeature] = useState(null);
  const [timeseriesData, setTimeseriesData] = useState(null);
  const [zoomDomain, setZoomDomain] = useState({ x: [0, +new Date()] });
  const [yDomain, setYDomain] = useState([0, 1]);
  const [deploymentDropdownOpen, setDeploymentDropdownOpen] = useState(false);
  const [selectedDeploymentIndex, setSelectedDeploymentIndex] = useState(0);
  const selectedDeployment = gliderDeployments[selectedDeploymentIndex];

  const zoomTimeDomain = [+zoomDomain.x[0], +zoomDomain.x[1]];

  function updateGeoJsonData(geoJson) {
    const { features } = geoJson;
    features.forEach(
      (feature) =>
        (feature.properties.timeNumeric = +new Date(feature.properties.time))
    );

    const timeseriesData = [];

    // Janky way to minimize/smooth out data:
    // Keep the feature with max depth out of every `chunkLength`-sized chunk.
    const chunkLength = 4;
    for (let i = 0; i < features.length; i += chunkLength) {
      let maxDepthFeature = null;
      let maxDepthInChunk = 0;
      for (let j = 0; j < chunkLength; j += 1) {
        const feature = features[i + j];
        if (feature) {
          const { properties } = feature;
          const { depth, time } = properties;
          // Add x/y props to top-level for ease of use with chart
          feature.time = new Date(time);
          feature.depth = depth;
          if (depth > maxDepthInChunk) {
            maxDepthInChunk = depth;
            maxDepthFeature = feature;
          }
        }
      }
      timeseriesData.push(maxDepthFeature);
    }
    // TODO https://formidable.com/open-source/victory/guides/zoom-on-large-datasets
    setGeoJsonData(geoJson);
    setTimeseriesData(timeseriesData);

    const maxDepth = timeseriesData.reduce(
      (maxDepth, { depth }) => Math.max(maxDepth, depth),
      0
    );
    setYDomain([maxDepth, 0]);
    setZoomDomain({
      x: [
        timeseriesData[0].time,
        timeseriesData[timeseriesData.length - 1].time,
      ],
    });
  }

  useEffect(() => {
    // See script/refresh_glider_data.js for how local glider data is fetched & transformed.
    updateGeoJsonData(selectedDeployment.geoJson);
  }, [selectedDeployment]);

  useEffect(() => {
    if (!geoJsonData) return;

    const webMercatorViewport = new WebMercatorViewport(viewPort);
    const [minLng, minLat, maxLng, maxLat] = bbox(geoJsonData);
    const { latitude, longitude, zoom } = webMercatorViewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 40 }
    );
    setDataViewPort({ latitude, longitude, zoom });
  }, [geoJsonData, viewPort]);

  const startFeature = geoJsonData && geoJsonData.features[0];
  const endFeature =
    geoJsonData && geoJsonData.features[geoJsonData.features.length - 1];
  if (startFeature) startFeature.properties.label = "Start";
  if (endFeature) endFeature.properties.label = "End";
  const startEndFeatureCollection = {
    type: "FeatureCollection",
    features: [startFeature, endFeature],
  };

  return (
    <div
      className={`w-full h-full ${
        dataViewPort ? "" : "spinner"
      } flex flex-col md:flex-row`}
    >
      <div className="w-full md:w-1/2 lg:w-1/3 mx-2 flex flex-col items-center bg-orstedBgLight rounded">
        <div className="flex flex-col items-center w-1/2 md:w-full">
          <a href="/technology-used">
            <img className="px-4 py-2" src={GliderImage} alt="Slocum Glider" />
          </a>
          <div className="relative inline-block text-left">
            <div className="flex flex-row items-center">
              <span className="rounded-md shadow-sm mx-2">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 bg-white text-sm leading-5 font-medium transition ease-in-out duration-150"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded={deploymentDropdownOpen}
                >
                  <span className="text-lg">{selectedDeployment.label}</span>
                  <span
                    className="-mr-1 ml-2 h-5 w-5 text-gray-700 hover:text-gray-500"
                    onClick={() =>
                      setDeploymentDropdownOpen(!deploymentDropdownOpen)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </span>
              <Link href={selectedDeployment.href} className="text-sm">
                Mission Summary
              </Link>
            </div>
            {deploymentDropdownOpen && (
              <div className="origin-top-right absolute rounded-md w-full z-10">
                <div className="rounded-md bg-white">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {gliderDeployments.map(
                      ({ label, href }, deploymentIndex) => (
                        <li
                          key={label}
                          className="hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap cursor-pointer"
                          onClick={() => {
                            setSelectedDeploymentIndex(deploymentIndex);
                            setDeploymentDropdownOpen(false);
                          }}
                        >
                          {label}
                        </li>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex-grow w-full p-2">
          <div className="w-full h-full border rounded border-orstedTextDark p-2 text-sm flex flex-row md:flex-col">
            <GliderDetails
              className="flex flex-1"
              hoverFeature={hoverFeature}
            />
            {timeseriesData && (
              <DepthChart
                className="flex flex-1 flex-col"
                data={timeseriesData}
                onHover={(feature) => {
                  setHoverFeature(feature);
                }}
                hoverFeature={hoverFeature}
                zoomDomain={zoomDomain}
                yDomain={yDomain}
                setZoomDomain={setZoomDomain}
                selectedDeploymentIndex={selectedDeploymentIndex}
              />
            )}
          </div>
        </div>
      </div>
      <MapGL
        ref={mapRef}
        mapboxApiAccessToken="pk.eyJ1Ijoia2hpbmVyIiwiYSI6ImNrZnIydWI3OTAyazgyeHBqaTVxZDJzbHgifQ.XGSpIcMl90rntU8H0Q0oyQ"
        mapStyle="mapbox://styles/mapbox/light-v9"
        {...viewPort}
        {...dataViewPort}
        width="100%"
        height="100%"
        onViewportChange={setViewPort}
        onHover={(event) => {
          const { features } = event;
          const feature =
            features && features.find((feature) => feature.layer.id === "data");
          setHoverFeature(feature);
        }}
        getCursor={({ isHovering }) => (isHovering ? "pointer" : "default")}
      >
        <Source type="geojson" data={geoJsonData}>
          <Layer
            id="data"
            type="circle"
            paint={{
              "circle-radius": [
                "case",
                [
                  "all",
                  [">=", ["get", "timeNumeric"], zoomTimeDomain[0]],
                  ["<=", ["get", "timeNumeric"], zoomTimeDomain[1]],
                ],
                3,
                2,
              ],
              "circle-color": "#4099da",
              "circle-opacity": [
                "case",
                [
                  "all",
                  [">=", ["get", "timeNumeric"], zoomTimeDomain[0]],
                  ["<=", ["get", "timeNumeric"], zoomTimeDomain[1]],
                ],
                0.5,
                0.2,
              ],
            }}
          />
          <Layer
            type="symbol"
            layout={{
              "text-field": ["get", "label"],
              "text-variable-anchor": ["right", "left", "top", "bottom"],
              "text-radial-offset": 0.5,
            }}
          />
        </Source>
        <Source type="geojson" data={startEndFeatureCollection}>
          <Layer
            type="circle"
            paint={{
              "circle-radius": 4,
              "circle-color": [
                "match",
                ["get", "label"],
                "Start",
                "green",
                "End",
                "red",
                /* other */ "#000000",
              ],
            }}
          />
        </Source>
        <Source
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: hoverFeature ? [hoverFeature] : [],
          }}
        >
          <Layer
            type="circle"
            paint={{
              "circle-radius": 6,
              "circle-color": "#4099da",
              "circle-opacity": 1,
              "circle-stroke-width": 3,
              "circle-stroke-color": "white",
            }}
          />
        </Source>
        {startFeature && <Pin feature={startFeature} color="green" />}
        {endFeature && <Pin feature={endFeature} color="red" />}
      </MapGL>
    </div>
  );
}
